<template>
  <div class="snippet-loading placeholder-glow">
    <div class="snippet-body">
      <span class="placeholder" style="width: 60%"></span>
      <span class="placeholder" style="width: 25%"></span>
      <span class="placeholder" style="width: 55%"></span>
      <span class="placeholder" style="width: 70%"></span>
    </div>
    <div class="snippet-aside">
      <span class="placeholder" style="width: 40%"></span>
    </div>
  </div>
</template>

<style lang="scss">
@import '~/assets/scss/variables';
.snippet-loading {
  border: 1px solid $gray-300;
  position: relative;
  min-height: 237px;

  .snippet-aside {
    min-width: 30rem;
  }

  .placeholder {
    color: $gray-500;
  }
}
</style>
